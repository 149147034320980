import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '../components/template-parts/Box';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <Box>
      <Typography variant="h1">Oops, that's a 404</Typography>
    </Box>
  </Layout>
);
